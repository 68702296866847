@font-face {
font-family: 'Rounded Semi';
src: url('../asset/Rounded/rounded-semibold.ttf');  
font-style: normal;
}

@font-face {
font-family: 'Pro Semi';
src: url('../asset/Pro/pro-semibold.otf');  
font-style: normal;
}


html,
body {
  padding: 0;
  margin: 0; 
  background: #fefefe !important;
}

a {
  color: inherit;
  text-decoration: none;
} 

* {
  box-sizing: border-box; 
  border: none;
  background: transparent !important;
  color: black !important;
  font-family: Pro Semi !important;
  font-weight: normal !important;
  box-shadow: none !important;  
  white-space: pre-line;
  text-align: justify;
  line-height: 1.3em;
}  

button{
  margin:  0px !important;
  padding: 0px !important; 
  max-height: 25px;
}

button:hover{
  box-shadow: none !important;
  border:  none !important;
}

button:focus{
  box-shadow: none !important;
  border:  none !important;
} 

input:hover{
  border: 1px solid #000 !important;
  border-right-width: 2px !important;
  border-bottom-width: 2px !important;
  padding: 15px;
  outline: none !important;
}

input:focus{
  border: 1px solid #000 !important;
  border-right-width: 2px !important;
  border-bottom-width: 2px !important;
  padding: 15px;
  outline: none !important;
} 

textarea:hover{
  border: 1px solid #000 !important;
  border-right-width: 2px !important;
  border-bottom-width: 2px !important;
  padding: 15px;
  outline: none !important;
}

textarea:focus{
  border: 1px solid #000 !important;
  border-right-width: 2px !important;
  border-bottom-width: 2px !important;
  padding: 15px;
  outline: none !important; 
} 

textarea:focus, input:focus{
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
} 

input{
  border: 1px solid #000 !important;
  border-right-width: 2px !important;
  border-bottom-width: 2px !important;
  padding: 15px;
}

textarea{
  border: 1px solid #000 !important;
  border-right-width: 2px !important;
  border-bottom-width: 2px !important;
  padding: 15px;
  min-height: 200px;
}

.desktop{
  display: block;
}

.mobile{
  display: none;
}

/*POSITION*/

.centerHeader{
  max-width: 580px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 100px;
  /*background: beige !important;*/
}

.center-z{
  z-index: 10; 
}

.left{
  float: left !important;
  text-align: left !important;
}

.right{
  float: right !important;
  text-align: right !important;
}


/*STYLE*/

.underline{
  border-bottom: 1px solid #000 !important; 
}

.italic{
  font-style: italic;
}

.opacity-05{
  opacity: 0.5;
} 

.no-wrap{
  white-space: nowrap;
}

.box{
  /*border: 1px solid #000 !important;
  border-right-width: 2px !important;
  border-bottom-width: 2px !important;*/
/*  padding: 15px;*/
  /*background-color: #F7F5F3 !important;
  border-bottom: 1px solid #e0e0e0;*/
} 

.blue{
  color: #0099ff !important;
}

.highlight{
  color: #e27c71 !important;
}

/*FONT*/

.heading{
  font-size: 22px; 
  margin-top: 5px;
  color: #262626;
}

.heading-high{
  font-size: 22px; 
  margin-top: 5px;
  background: #e27c71 !important;
  color: #fefefe !important;
  padding: 5px;
}

.headingLarge{
  font-size: 38px; 
  margin-top: 0px;
  padding-top: 15px;
  border-top: 2px solid #262626 !important;
  /*background: #F7F5F3 !important;
  border-bottom: 1px solid #000 !important;  */
}

.subheading{
  font-size: 20px;
  opacity: 0.5;
  color: #262626;
}

.subsubheading{
  font-size: 20px;
  opacity: 0.3;
  color: #262626;
}

.line{
  border-top: 2px solid #262626 !important;
}

/*.background{ 
  background: #F7F5F3 !important; 
}*/

.background{
    /*background-color: #F7F5F3 !important;
    border-bottom: 1px solid #e0e0e0;*/
}

/*MARGIN*/

.copyright{
  position: absolute !important;
  margin-top: 100px;
  margin-left: 250px;
}

.top-0{
  margin-top: 0px !important;
}

.top-10{
  margin-top: 10px !important;
}

.top-20{
  margin-top: 20px !important;
}

.top-40{
  margin-top: 40px !important;
}

.just{
  text-align: justify;
}

.bottom-0{
  margin-bottom: 0px;
}

.bottom-10{
  margin-bottom: 10px;
}

.bottom-20{
  margin-bottom: 20px;
}

.bottom-40{
  margin-bottom: 40px;
}

.right-5{
  margin-right: 5px;
}

.top-minus-5{
  margin-top: -5px;
}

.top-minus-10{
  margin-top: -10px;
}

.top-minus-20{
  margin-top: -20px;
}

.top-minus-25{
  margin-top: -25px;
}

.top-minus-30{
  margin-top: -30px;
}

/*PADDING*/
 
.padding-20{
  padding: 20px;
  text-transform: uppercase;
} 
 
.padding-120{
  padding: 120px; 
}  

.loader {
  width: 28px;
  height: 28px;
  border: 2px dotted #262626;
  border-radius: 50%;
  display: inline-block;
  position: fixed;
  bottom:  25px;
  left: 25px;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

/*IMAGES*/

.logo{
  /*border: 1px solid #000;*/
  height: 65px;
  width: 65px;
  /*background-color: beige !important;*/
  padding-left: 0px !important;
  margin-left: -8px; 
  margin-top: 20px;
  margin-bottom: 20px;
}  

@media (min-width: 768px) and (max-width: 1024px) {
        

}

/*
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      
  .desktop{
    display: none;
  } 
  .mobile{
    display: block;
  }
          
}

/*
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) { 
          

          
}

@media (max-width: 479px) {     
  .padding-mobile{
    padding: 20px;
  }
  .desktop{
    display: none;
  } 
  .mobile{
    display: block;
  }
  .centerHeader{ 
    margin-top: 20px; 
  }
  .mobile-left{
    margin-left: 25px;
  }

  .subsubheading{
    font-size: 16px;
    opacity: 0.3;
    color: #262626;
  }
} 